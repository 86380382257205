/* @import "@fullcalendar/core/main.css"; */

:root {
  --Dark: #183440;
  --DarkHover: #194355;
  --Light: #dddfe1;
  --Muted: #37454b;
  --MutedHover: #1d3844;
  --Red: #a30000;
  --RedHover: #7d0000;
}

body {
  margin: 0;
  font-family: "Sora", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Asign color for buttons and text */

.bgDark {
  background-color: var(--Dark) !important;
}

.btnGreen {
  background-color: var(--DarkHover) !important;
  border: none !important;
}
.btnRed {
  background-color: var(--Red) !important;
  border: none !important;
}

.bgLight {
  background-color: var(--Light) !important;
}

.btnDark {
  background-color: var(--Dark) !important;
  color: var(--Light) !important;
  border: none !important;
  padding: 8px !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  border-radius: 15px !important;
}

.btnDark:hover {
  background-color: var(--DarkHover) !important;
}

.btnMuted {
  background-color: var(--Muted) !important;
  color: var(--Light) !important;
  border: none !important;
  padding: 8px !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  border-radius: 15px !important;
  opacity: 0.5 !important;
}

.btnMuted:hover {
  background-color: var(--MutedHover) !important;
  opacity: 1 !important;
}

.btnBlank {
  background-color: transparent !important;
  color: var(--Dark) !important;
  border: none !important;
  padding: 8px !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  border-radius: 15px !important;
}

.btnBlank:hover {
  background-color: var(--Dark) !important;
  color: var(--Light) !important;
  transition: 500ms;
}

.btnDanger {
  background-color: var(--Red) !important;
  color: var(--Light) !important;
  border: none !important;
  padding: 8px !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  border-radius: 15px !important;
  cursor: pointer !important;
}

.btnDanger:hover {
  background-color: var(--RedHover) !important;
  transition: 500ms;
}

.txtDark {
  color: var(--Dark) !important;
}

.bgLight {
  background-color: var(--Light) !important;
}

.txtLight {
  color: var(--Light) !important;
}

table tr {
  border-spacing: 0 20px;
}

a {
  text-decoration: none !important;
}

/* Loadign */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--Dark) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.buttonFloat {
  float: left;
  bottom: 0;
}

.customPill {
  border: none !important;
  padding: 3px 10px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  border-radius: 15px !important;
  mix-blend-mode: difference !important;
}

.dlIcon {
  color: var(--Dark) !important;
  border: none !important;
}

.dlIcon:hover {
  color: green !important;
  cursor: pointer;
}

.ulIcon {
  color: var(--Dark) !important;
  border: none !important;
  cursor: pointer !important;
}

.ulIcon:hover {
  color: red !important;
}

.Bussy {
  color: Red;
}

.Online {
  color: green;
}

.Offline {
  color: gray;
}
